<script setup lang="ts">
import AppTopBar from './components/AppTopBar.vue'
import AppFooter from './components/AppFooter.vue'
import { useQuoteStore } from './stores/QuoteStore'

const store = useQuoteStore()
</script>

<template>
  <div
    class="min-h-screen flex relative static surface-ground layout-wrapper"
  >
    <div class="layout-main-container min-h-screen flex flex-column relative flex-auto">
      <app-top-bar class="no-print" />
      <div class="lg:px-5 lg:pt-5 flex flex-column flex-auto">
        <p-toast
          position="top-left"
          style="z-index: 20;"
        />
        <p-confirm-dialog class="z-4" />
        <div id="quote-wrapper" class="border-1 border-solid surface-border lg:border-round surface-section flex-auto">
          <div class="md:hidden text-center mt-3">
            <a
              class="border-round border-1 border-transparent hover:border-red-500 px-2 py-1 router-link text-red-500 text-xl md:text-2xl font-bold"
              href="tel:+12529771131"
            >
              <i class="fa fa-phone text-primary text-xl mr-2" />
              (252) 977-1131
            </a>
          </div>
          <router-view />
        </div>
      </div>
      <app-footer class="no-print" />
      <section class="print-only mt-4">
        <article class="border-1 border-300 border-round bg-white p-2">
          <h2 class="text-2xl text-primary-800 m-0 mb-2 p-0">
            Contact Daughtridge Sales
          </h2>
          <ul class="list-none m-0 p-0">
            <li class="my-1">
              Phone: (252) 977-1131
            </li>
            <li class="my-1">
              Sales Team: {{ store.quote.salesperson_name }} - {{ store.quote.salesperson_email }}
            </li>
            <li class="my-1">
              General Email: info@dascosales.com
            </li>
          </ul>
        </article>
      </section>
    </div>
  </div>
</template>

<style lang="scss">
.layout-wrapper {
  .layout-main-container {
    margin-left: 0;
  }
}

.p-dialog-mask.p-component-overlay {
  z-index: 4 !important;
}
</style>
