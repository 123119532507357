export enum FlowState {
  Complete = 'complete',
  Error = 'error',
  Idle = 'idle',
  Loading = 'loading',
  LoadingHistory = 'loading-history',
  NotFound = 'not-found',
  Pending = 'pending',
  Saving = 'saving'
}
