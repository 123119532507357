import type { CodeDesc } from '../interfaces/CodeDesc'

export enum LifecycleStatus {
  Active = 'active',
  Archived = 'archived',
  Discontinued = 'discontinued',
  Draft = 'draft',
  Pending = 'pending',
  Unknown = 'unknown',
  Unverified = 'unverified'
}

export const lifecyclesArray: Array<CodeDesc> = Object
  .entries(LifecycleStatus)
  .map((i: any) => ({ code: i[0] || null, desc: i[1] || null }))

export const lifecycleIcons: any = {
  [LifecycleStatus.Active]: 'fa-fw fa fa-check text-green-400',
  [LifecycleStatus.Archived]: 'fa-fw fa fa-times-circle',
  [LifecycleStatus.Discontinued]: 'fa-fw fa fa-times-circle',
  [LifecycleStatus.Draft]: 'fa-fw fa file-pen',
  [LifecycleStatus.Pending]: 'fa-fw fa fa-hourglass-clock text-purple-600',
  [LifecycleStatus.Unknown]: 'fa-fw fa fa-circle-question text-600',
  [LifecycleStatus.Unverified]: 'fa-fw fa fa-times-circle text-600'
}
