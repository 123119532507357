<script setup>
import { useAppStore } from '../stores/AppStore'

const appStore = useAppStore()
const year = new Date().getFullYear()
</script>

<template>
  <section>
    <p class="text-center text-2xl p-2 pb-0 mb-0">
      Daughtridge Sales is now part of MCE. Same great company, now with more solutions to serve you better.
    </p>
    <div class="py-2 text-center">
      <a
        class="router-link border-bottom-1 border-transparent text-primary hover:border-red-500"
        href="https://mceautomation.com/about/news/-acquisition-announcement-daughtridge-sales-company-inc-/"
        target="_blank"
      >
        Read Press Release
      </a>
    </div>
    <div class="px-4 flex flex-column md:flex-row align-items-center justify-content-center">
      <div class="md:w-6 lg:w-4 text-center">
        <img
          src="/images/Daughtridge-Sales-Color-NoSpacing.svg"
          alt="Logo Daughtridge Sales an MCE company"
          class="h-3rem w-12 md:w-auto"
        >
      </div>
      <div class="md:w-6 lg:w-4 text-center">
        <img
          src="/images/mce-wsp-logo-color.svg"
          alt="Logo for MCE Automation"
          class="h-4rem mt-4 md:mt-0"
        >
      </div>
    </div>
  </section>
  <div class="surface-ground px-4 md:px-6 lg:px-8">
    <div class="py-2 flex flex-column sm:flex-row align-items-center justify-content-center">
      <div class="text-xs mt-2 line-height-3 ">
        &copy; {{ year }} Daughtridge Sales All rights reserved
      </div>
      <div class="md:ml-2 mt-2 line-height-3 ">
        v{{ appStore.version }}
      </div>
    </div>
  </div>
</template>
