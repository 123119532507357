/**
 * Base Quote Service for Quotes retrieved by Shared Access token
 */
import { httpsCallable } from 'firebase/functions'
import { useSentry } from '../composables/sentry'
import type { ResponseSimple } from '../interfaces/Response'
import { functions } from './FirebaseFunctionsService'

const s = useSentry()

const QuoteServiceSharedAccess = {
  getQuoteSharedAccess: async (payload: any): Promise<ResponseSimple> => {
    const {
      accessToken = null
    } = payload
    if (!accessToken) {
      return {
        success: false,
        msg: 'Access token required'
      }
    }
    let resData: any = {}
    try {
      const getQuoteSharedAccess = httpsCallable(functions, 'quotes-getQuoteSharedAccess')
      const res: any = await getQuoteSharedAccess(payload)

      if (!res?.data?.success) {
        resData = { ...res?.data?.data }
        throw new Error('Error retrieving the quote')
      }
      return {
        success: true,
        data: res?.data?.data,
        msg: 'Successfully retrieved quote'
      }
    }
    catch (error: any) {
      s.handleError(error)
      return {
        success: false,
        data: { ...resData },
        msg: 'Error retrieving quote'
      }
    }
  }
}

export default QuoteServiceSharedAccess
