export default [
  {
    path: '/quotes',
    name: 'Quote',
    component: () => import('../views/QuoteSingle.vue')
  },
  {
    path: '/quotes/:doc_no',
    name: 'QuoteSingle',
    component: () => import('../views/QuoteSingle.vue')
  },
  {
    path: '/quotes/:doc_no/shared',
    name: 'QuoteSingleShared',
    component: () => import('../views/QuoteSingleShared.vue')
  }
]
