import type { serverTimestamp } from 'firebase/firestore'
import type { ObjectKeys } from '../interfaces/ObjectKeys'
import type { AccountSimple } from './AccountServiceInterfaceAccount'
import type { UserSimple } from './UserInterfaces'
import { toQuoteLineItems } from './QuoteServiceLineItems'

export interface Quote extends ObjectKeys {
  id: string
  archived_versions?: number
  associated_accounts?: Array<string>
  associated_users?: Array<string>
  bill_to_account?: string | null
  blanket_quote: boolean
  checkout_date?: number | typeof serverTimestamp | null
  checkout_description?: string | null
  checkout_email?: string | null
  checkout_name?: string | null
  checkout_state?: 'available' | 'locked' | 'frozen'
  checkout_uid?: string | null
  created_date: number | typeof serverTimestamp | null
  created_email: string | null
  created_name: string | null
  created_source: string | null
  created_uid: string | null
  doc_no: string | null
  doc_type: 'quote'
  external_rfq_no?: string | null
  items: Array<any> // Array<ILineItemItemQuote>
  last_modified_date?: number | typeof serverTimestamp | null
  last_modified_email?: string | null
  last_modified_name?: string | null
  last_modified_uid?: string | null
  lifecycle_status: string
  notes_count: number
  notes_internal_count: number
  quote_no?: string
  rfq_require_by_date?: any // required for Primevue Calendar
  rfq_source: string | null
  rfq_customer?: any | null
  sales_order_no: string | null
  salesperson_email: string | null
  salesperson_name: string | null
  salesperson_uid: string | null
  sell_to_account?: AccountSimple | null
  sell_to_user?: UserSimple | null
  ship_to_account?: AccountSimple | null
}

export const toQuote = (payload: any = {}): Quote => {
  const {
    doc = {}
  } = payload
  return {
    id: doc?.id || null,
    archived_versions: doc?.archived_versions || 0,
    associated_accounts: doc?.associated_accounts || [],
    associated_users: doc?.associated_users || [],
    bill_to_account: doc?.bill_to_account || null,
    blanket_quote: doc?.blanket_quote || false,
    checkout_date: doc?.checkout_date || null,
    checkout_description: doc?.checkout_description || null,
    checkout_email: doc?.checkout_email || null,
    checkout_name: doc?.checkout_name || null,
    checkout_state: doc?.checkout_state || 'available',
    checkout_uid: doc?.checkout_uid || null,
    created_date: doc?.created_date || null,
    created_email: doc?.created_email || null,
    created_name: doc?.created_name || null,
    created_source: doc?.created_source || 'unknown',
    created_uid: doc?.created_uid || null,
    doc_no: doc?.quote_no || null,
    doc_type: 'quote',
    external_rfq_no: doc?.external_rfq_no || null,
    items: toQuoteLineItems(doc?.items) || [],
    last_modified_date: doc?.last_modified_date || null,
    last_modified_email: doc?.last_modified_email || null,
    last_modified_name: doc?.last_modified_name || null,
    last_modified_uid: doc?.last_modified_uid || null,
    lifecycle_status: doc?.lifecycle_status || 'unknown',
    notes_count: doc?.notes_count || 0,
    notes_internal_count: doc?.notes_internal_count || 0,
    quote_no: doc?.quote_no || null,
    rfq_require_by_date: doc?.rfq_require_by_date || null,
    rfq_source: doc?.rfq_source || null,
    rfq_customer: doc?.rfq_customer || null,
    sales_order_no: doc?.sales_order_no || null,
    salesperson_email: doc?.salesperson_email || null,
    salesperson_name: doc?.salesperson_name || null,
    salesperson_uid: doc?.salesperson_uid || null,
    sell_to_account: doc?.sell_to_account || null,
    sell_to_user: doc?.sell_to_user || null,
    ship_to_account: doc?.ship_to_account || null
  }
}
