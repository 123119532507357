// PrimeVue Components
import Button from 'primevue/button'
import ConfirmDialog from 'primevue/confirmdialog'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
import InputMask from 'primevue/inputmask'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'

// PrimeVue Directives
import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'

// Primevue Services
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

// Dasco Versions of Primevue Components
// import DascoCard from './components/DascoCard.vue'

export const primevueSetup = async (app: any) => {
  // Register PrimeVue Services
  app.use(ToastService)
  app.use(ConfirmationService)
  // Register PrimeVue components
  app.component('PBtn', Button)
  app.component('PDropdown', Dropdown)
  app.component('PInputMask', InputMask)
  app.component('PInputNumber', InputNumber)
  app.component('PInputText', InputText)
  app.component('PToast', Toast)
  app.component('PConfirmDialog', ConfirmDialog)
  // Register PrimeVue directives
  app.directive('badge', BadgeDirective)
  app.directive('ripple', Ripple)
  app.directive('styleclass', StyleClass)
  app.directive('tooltip', Tooltip)
  return app
}
