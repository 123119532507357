import { httpsCallable } from 'firebase/functions'
import { useSentry } from '../composables/sentry'
import type { ResponseSimple } from '../interfaces/Response'
import { functions } from './FirebaseFunctionsService'

const s = useSentry()

const StandardFootersService = {
  getStandardFooters: async (): Promise<ResponseSimple> => {
    let resData: any = {}
    try {
      const getStandardFooters = httpsCallable(functions, 'businessCentral-getStandardFooters')
      const res: any = await getStandardFooters()

      if (!res?.data?.success) {
        resData = { ...res?.data?.data }
        throw new Error('Error retrieving the standard footers')
      }

      return {
        success: true,
        data: res?.data?.data,
        msg: 'Successfully retrieved standard footers'
      }
    }
    catch (error: any) {
      s.handleError(error)
      console.error(error)
      return {
        success: false,
        data: { ...resData },
        msg: 'Error retrieving standard footers'
      }
    }
  }
}

export default StandardFootersService
