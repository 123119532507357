/**
 * ItemBase
 * Legacy file ref is src/data/IItemBase.ts
 *
 * This is the base object for all types of items.
 * An item could be anything from a single bolt used in an assembly to a configuration built using the configurator.
 *
 * Each item interface will extend from this interface.
 *
 * In the future all interfaces in the Items Module will be migrated and refactored to use this interface and any of its extensions
 *
 * Prop values for conditional settings should be stored as lowercase and then use the toTitleCase() util for any titlecase needs in UI
 */
import type { FieldValue, Timestamp } from 'firebase/firestore'
import { LifecycleStatus } from '../constants/lifecycles'
import { ItemType } from '../constants/itemTypes'
import { priceDefault } from '../constants/priceDefault'
import type { LabelValue } from '../interfaces/LabelValue'
import { buildSearchDescription } from '../utils/buildSearchDescription'
import type { ObjectKeys } from '../interfaces/ObjectKeys'
import { formatStringCleanSpaces } from '../utils/formatStringCleanSpaces'

export interface ItemBase extends ObjectKeys {
  fb_id?: string | null // Firestore ID
  active: boolean
  brand_name: string | null
  category: string | null
  category2?: string | null
  category3?: string | null
  category4?: string | null
  category5?: string | null
  cdn_handle_primary_image?: string | null
  cdn_handle_datasheet?: string | null
  created_date?: string | Timestamp | FieldValue | null // Some really early created items may have used a string
  created_name?: string | null
  created_email?: string | null
  created_uid?: string | null
  description: string | null // all items whether configured or not
  description_searchable: string | null
  description_override?: string | null
  has_description_override: boolean
  item_legacy_fb_id?: string | null
  item_no?: string | null
  item_no2?: string | null
  item_type: ItemType
  last_modified_date?: Timestamp | FieldValue
  last_modified_name?: string | null
  last_modified_email?: string | null
  last_modified_uid?: string | null
  lifecycle_status: LifecycleStatus
  // list_price?: number // deprecated v3
  list_price_base?: number | null // Base list price from the configuration model OR the list price of a non-configured item
  list_price_total?: number | null // This is the summed list price of a configuration model OR same as list_price_base for non-configured items
  model?: string | null
  model_uri?: string | null
  part_no_cross_ref_id: string | null
  product_class_code?: string | null
  product_group_code?: string | null
  published_scope: Array<string> // app names
  sell_price?: number // Sell price is used when an item has a set sell price and it is not calculated by PCPG or any other bracket pricing
  stocked: boolean
  shelf: string | null // primary shelf location
  synonym: string | null
  vendor: string | null
  vendor_no: string | null
  vendor_item_no?: string | null
  upc?: string | null
  gtin?: string | null
  raw?: any | null
  // To be deprecated
  vendor_name?: string | null
  category_detail?: string | null
  // Deprecated v3
  // list_price?: number // deprecated as had shared purpose of base list price for configuration calculation and summed list price total // caused a doubling effect on quotes when using the new Pricing Service shared with the configurator.
  // locations: Array<ILocation> // No longer needed with Business Central
  // Should we deprecate on premise sync status??? 2022-07-21 @bmrankin
  // on_premise_sync_status: 'not-required' | 'created-not-sync-with-on-premise' | 'synced' | 'review-sync-status' | 'not-synced'
  // on_premise_last_sync_date?: firebase.firestore.Timestamp
}

export interface ItemConfigured extends ItemBase {
  base_item?: string | null // Configured model minus additional options
  base_model?: string | null // Configured model minus range
  base_model_name: string | null // Base Model
  base_model_fb_id: string | null // Base Model fb id
  base_model_uri: string | null // Base Model uri
  configuration?: any
  configurator_model_name: string | null
  configurator_model_uri: string | null
  configurator_fb_id: string | null
  configurator_desc: string | null // desc on configured items only
  description_builder?: object // Config for description builder (See WIKA 2021-07-14)
  list_prices_has_fixed?: boolean
}

const publishedScopes = (scopes: Array<string>): Array<string> => {
  if (!scopes.length)
    return []

  const x: any = []
  scopes.forEach(i => x.push(i.toLowerCase()))
  return x
}

/**
 * Use as a mapping function when the item_type is not configured.
 * If item_type === Unknown then return base map with `raw` prop that holds database raw data
 */
const toItemBase = (payload: any = {}): ItemBase => {
  const {
    fb_id = null,
    item = {},
    item_type = ItemType.Unknown
  } = payload

  const mappedItem: ItemBase = {
    fb_id, // Firebase ID
    active: item?.active || false,
    brand_name: item?.brand_name || null,
    category: item?.category,
    category_detail: item?.category2 || item?.category_detail || null,
    category2: item?.category2 || item?.category_detail || null,
    category3: item?.category3 || item?.base_model_name || null,
    category4: item?.category4 || null,
    category5: item?.category5 || null,
    cdn_handle_primary_image: item?.cdn_handle_primary_image || null,
    cdn_handle_datasheet: item?.cdn_handle_datasheet || null,
    created_date: item?.created_date || null,
    created_name: item?.created_name || null,
    created_email: item?.created_email || null,
    created_uid: item?.created_uid || null,
    description: item?.description || null,
    description_searchable: item?.description ? buildSearchDescription(item?.description) : null,
    description_override: item?.description_override || null,
    has_description_override: item?.has_description_override || item?.description_override?.length || false,
    item_legacy_fb_id: item?.item_legacy_fb_id || null,
    item_no: item?.item_no || null,
    item_no2: item?.item_no2 || null,
    item_type: item?.item_type || item_type,
    last_modified_date: item?.last_modified_date || null,
    last_modified_name: item?.last_modified_name || null,
    last_modified_email: item?.last_modified_email || null,
    last_modified_uid: item?.last_modified_uid || null,
    lifecycle_status: item?.lifecycle_status?.toLowerCase() || LifecycleStatus.Draft,
    // list_price: +item?.list_price || 0, // deprecated
    list_price_base: +item?.list_price_base || 0, // TODO if does not exist??? list_price may be corrupted or incorrect on existing items
    list_price_total: +item?.list_price_total || 0, // TODO ???
    model: item?.model || null,
    model_uri: item?.model_uri || null,
    part_no_cross_ref_id: item?.part_no_cross_ref_id || null,
    product_class_code: item?.product_class_code || null,
    product_group_code: item?.product_group_code || null,
    published_scope: item?.published_scope ? publishedScopes(item?.published_scope) : [],
    sell_price: item?.sell_price || null,
    shelf: item?.shelf || null,
    stocked: item?.stocked || false,
    synonym: item?.synonym || null,
    vendor: item?.vendor || item?.vendor_name || item?.brand_name,
    vendor_no: item?.vendor_no || null,
    vendor_item_no: item?.vendor_item_no || null,
    upc: item?.upc || null,
    gtin: item?.gtin || null,
  }

  // If item_type is unknown included raw database data
  if (mappedItem.item_type === ItemType.Unknown)
    mappedItem.raw = item

  return mappedItem
}

// CONFIGURED ITEMS
export const toItemConfigured = (payload: any = {}): ItemConfigured => {
  const {
    fb_id = null,
    item = {}
  } = payload
  return {
    fb_id, // Firebase ID
    active: item?.active || false,
    base_item: item?.base_item || null,
    base_model: item?.base_model || null,
    base_model_name: item?.base_model_name || null,
    base_model_fb_id: item?.base_model_fb_id || null,
    base_model_uri: item?.base_model_uri || null,
    brand_name: item?.brand_name || null,
    category: item?.category,
    category_detail: item?.category2 || item?.category_detail || null,
    category2: item?.category2 || item?.category_detail || null,
    category3: item?.category3 || item?.base_model_name || null,
    category4: item?.category4 || null,
    category5: item?.category5 || null,
    cdn_handle_primary_image: item?.cdn_handle_primary_image || null,
    cdn_handle_datasheet: item?.cdn_handle_datasheet || null,
    configuration: item?.configuration || {},
    configurator_model_name: item?.configurator_model_name || null,
    configurator_model_uri: item?.configurator_model_uri || null,
    configurator_fb_id: item?.configurator_fb_id || null,
    configurator_desc: item?.configurator_desc || null,
    created_date: item?.created_date || null,
    created_name: item?.created_name || null,
    created_email: item?.created_email || null,
    created_uid: item?.created_uid || null,
    description: item?.description || null,
    description_searchable: item?.description ? buildSearchDescription(item?.description) : null,
    description_builder: item?.description_builder || null,
    description_override: item?.description_override || null,
    has_description_override: item?.has_description_override || item?.description_override?.length || false,
    item_legacy_fb_id: item?.item_legacy_fb_id || null,
    item_no: item?.item_no || null,
    item_no2: item?.item_no2 || null,
    item_type: item?.item_type || ItemType.Configured,
    last_modified_date: item?.last_modified_date || null,
    last_modified_name: item?.last_modified_name || null,
    last_modified_email: item?.last_modified_email || null,
    last_modified_uid: item?.last_modified_uid || null,
    lifecycle_status: item?.lifecycle_status?.toLowerCase() || LifecycleStatus.Draft,
    // list_price: +item?.list_price || 0, // deprecated
    list_price_base: +item?.list_price_base || 0, // TODO if does not exist??? list_price may be corrupted or incorrect on existing items
    list_price_total: +item?.list_price_total || 0, // TODO ???
    list_prices_has_fixed: item?.configuration ? item?.configuration?.list_prices_has_fixed : false,
    model: item?.model || null,
    model_uri: item?.model_uri || null,
    part_no_cross_ref_id: item?.part_no_cross_ref_id || null,
    product_class_code: item?.product_class_code || null,
    product_group_code: item?.product_group_code || null,
    published_scope: item?.published_scope ? publishedScopes(item?.published_scope) : [],
    sell_price: item?.sell_price || null,
    shelf: item?.shelf || null,
    stocked: item?.stocked || false,
    synonym: item?.synonym || null,
    vendor: item?.vendor || item?.vendor_name || item?.brand_name,
    vendor_no: item?.vendor_no || null,
    vendor_item_no: item?.vendor_item_no || null,
    upc: item?.upc || null,
    gtin: item?.gtin || null
  }
}

export const toItemConfiguratorUpdateItemFromConfigurator = (payload: any): ItemConfigured => {
  const {
    configuration,
    description,
    item,
    listPrice,
    model
  } = payload

  // Currently does not handle any legacy information
  return {
    fb_id: item.fb_id,
    active: item?.active || true,
    base_item: description.baseItem || null,
    base_model: description.baseModel || null,
    base_model_name: model.base_model_name || null,
    base_model_fb_id: model.base_model_fb_id || null,
    base_model_uri: model.base_model_uri || null,
    brand_name: model.brand_name || null,
    category: model.category,
    category_detail: model.category2 || model.category_detail || null,
    category2: model.category2 || model.category_detail || null,
    category3: model.category3 || model.base_model_name || null,
    category4: model.category4 || null,
    category5: model.category5 || null,
    cdn_handle_primary_image: model.cdn_handle_primary_image || null,
    cdn_handle_datasheet: model.cdn_handle_datasheet || null,
    configuration,
    configurator_model_name: model.model || null,
    configurator_model_uri: model.model_uri || null,
    configurator_fb_id: model.id || null,
    configurator_desc: description.full || null,
    description: description.full || null,
    description_searchable: description?.flat || null,
    has_description_override: false,
    item_legacy_fb_id: item.item_legacy_fb_id || null,
    item_no: item.item_no || null,
    item_no2: item.item_no2 || null,
    item_type: ItemType.Configured,
    lifecycle_status: item.lifecycle_status || LifecycleStatus.Active,
    // list_price: listPrice || priceDefault,
    list_price_base: item.list_price_base || 0, // TODO confirm 0 or priceDefault
    list_price_total: listPrice || priceDefault,
    list_prices_has_fixed: model.list_prices_has_fixed,
    part_no_cross_ref_id: null,
    product_class_code: model.product_class_code || null,
    product_group_code: model.product_group_code || null,
    published_scope: item.published_scope || [],
    shelf: item.shelf || null,
    stocked: item.stocked || false,
    synonym: item.synonym || null,
    vendor: model.brand_name || model.vendor || model.vendor_name,
    vendor_no: model.vendor_no || null,
    vendor_item_no: item.vendor_item_no || null,
    upc: item.upc || null,
    gtin: item.gtin || null
  }
}

/**
 * For creating new items from the configurator
 * @param payload
 * @return {any}
 */
export const toItemConfiguratorNewItem = (payload: any): ItemConfigured => {
  const {
    configuration,
    description,
    listPrice,
    model
  } = payload

  // Currently does not handle any legacy information
  return {
    active: true,
    base_item: description.baseItem || null,
    base_model: description.baseModel || null,
    base_model_name: model.base_model_name || null,
    base_model_fb_id: model.base_model_fb_id || null,
    base_model_uri: model.base_model_uri || null,
    brand_name: model.brand_name || null,
    category: model.category,
    category_detail: model.category2 || model.category_detail || null,
    category2: model.category2 || model.category_detail || null,
    category3: model.category3 || model.base_model_name || null,
    category4: model.category4 || null,
    category5: model.category5 || null,
    cdn_handle_primary_image: model.cdn_handle_primary_image || null,
    cdn_handle_datasheet: model.cdn_handle_datasheet || null,
    configuration,
    configurator_model_name: model.model || null,
    configurator_model_uri: model.model_uri || null,
    configurator_fb_id: model.id || null,
    configurator_desc: description.full || null,
    description: description.full || null,
    description_searchable: description?.flat || null,
    has_description_override: false,
    item_legacy_fb_id: null,
    item_no: null,
    item_no2: null,
    item_type: ItemType.Configured,
    lifecycle_status: LifecycleStatus.Active,
    // list_price: listPrice || priceDefault,
    list_price_base: model.list_price_base || model.list_price || 0, // TODO confirm list_price, 0 or priceDefault
    list_price_total: listPrice || 0, // TODO confirm 0 or priceDefault
    list_prices_has_fixed: model.list_prices_has_fixed,
    part_no_cross_ref_id: null,
    product_class_code: model.product_class_code || null,
    product_group_code: model.product_group_code || null,
    published_scope: [],
    shelf: null,
    stocked: false,
    synonym: null,
    vendor: model.brand_name || model.vendor || model.vendor_name,
    vendor_no: model.vendor_no || null,
    vendor_item_no: null,
    upc: null,
    gtin: null
  }
}

// as of 2023-01-25 it looks like any item that is not configured can just use ItemBase and toItemBase for data reads. Quote line items will be handled in the Quote Interfaces - @bmrankin

// NON-CONFIGURED ITEMS
// e.g. the old `2*` numbers, Items that cannot be configured

export interface NonConfiguredInfo extends ObjectKeys {
  base_model: string | null
  reason: string | null
  reason_notes: string | null
  resource1?: LabelValue | null
}

export interface ItemNonConfigured extends ItemBase {
  item_cost: number | null
  nonconfigured_info: NonConfiguredInfo
}

export const toItemNonConfigured = (payload: any): ItemNonConfigured => {
  const {
    item = {}
  } = payload

  return {
    ...toItemBase(payload),
    item_cost: item.item_cost || priceDefault,
    nonconfigured_info: {
      base_model: item?.nonconfigured_info?.base_model || null,
      reason: item?.nonconfigured_info?.reason || null,
      reason_notes: item?.nonconfigured_info?.reason_notes || null,
      resource1: item?.nonconfigured_info?.resource1 || null,
    }
  }
}

/**
 * For creating new items that do not have a configurator
 * Currently not for components or non-inventory items
 * @param payload
 * @return {any}
 */
export const toItemNonConfiguratorNewItem = (payload: any): ItemNonConfigured => {
  const {
    item,
    nonconfigured_info
  } = payload
  const description = formatStringCleanSpaces(item?.description)
  // Currently does not handle any legacy information
  return {
    active: true,
    brand_name: item.vendor_name || null,
    category: item.category,
    category_detail: item.category2 || item.category_detail || null,
    category2: item.category2 || item.category_detail || null,
    category3: null,
    category4: null,
    category5: null,
    cdn_handle_primary_image: null,
    cdn_handle_datasheet: null,
    description,
    description_searchable: buildSearchDescription(description),
    has_description_override: false,
    item_legacy_fb_id: null,
    item_cost: item.item_cost,
    item_no: null,
    item_no2: null,
    item_type: ItemType.NonConfigured,
    lifecycle_status: LifecycleStatus.Active,
    list_price_base: item.list_price_total,
    list_price_total: item.list_price_total, // TODO confirm 0 or priceDefault
    list_prices_has_fixed: false,
    nonconfigured_info: {
      base_model: nonconfigured_info.base_model,
      reason: nonconfigured_info.reason,
      reason_notes: nonconfigured_info.reason_notes
      // resource1: null
    },
    part_no_cross_ref_id: null,
    product_class_code: null,
    product_group_code: null,
    published_scope: [],
    sell_price: item?.sell_price,
    shelf: null,
    stocked: false,
    synonym: null,
    vendor: item.vendor_name,
    vendor_no: item.vendor_no || null,
    vendor_item_no: item.vendor_item_no || null,
    upc: item.upc || null,
    gtin: item.gtin || null
  }
}

// COMPONENT ITEMS
// e.g. Bolts, screws, glycerin, tags, etc
export interface ItemComponent extends ItemBase {}

// NON-INVENTORY ITEMS
// TODO
// e.g. CALIBRATION as a service, Handling, etc
export interface ItemNonInventory extends ItemBase {}

// Send item doc to correct mapping function
export const toItem = (payload: any = {}): ItemBase | ItemConfigured => {
  const {
    item,
    item_type = ItemType.Unknown
  } = payload
  let mappedItem: ItemBase | ItemConfigured

  if (item_type === ItemType.NonConfigured)
    mappedItem = toItemNonConfigured(payload)
  else if (item_type === ItemType.Configured)
    mappedItem = toItemConfigured(payload)
  else if (item?.configuration || item?.configurator_model_uri)
    mappedItem = toItemConfigured(payload)
  else
    mappedItem = toItemBase(payload)

  return mappedItem
}
