import { defineStore } from 'pinia'
import { name as appName, version as appVersion } from '../../package.json'
import type { ObjectKeys } from '../interfaces/ObjectKeys'

interface AppStore extends ObjectKeys {
  application: string
  version: string
}

export const useAppStore = defineStore('AppStore', {
  state: (): AppStore => ({
    application: appName,
    version: appVersion
  }),
  getters: {},
  actions: {}
})
