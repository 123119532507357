import { ItemType } from '../constants/itemTypes'
import { lineItemScope } from '../constants/lineItemScope'
import { getGuid } from '../utils/getGuid'
import type {
  ItemConfigured,
  // ItemComponent,
  ItemNonConfigured,
  // ItemNonInventory
} from './ItemServiceInterfaces'
import { toItem, toItemConfigured } from './ItemServiceInterfaces'

export interface QuoteLineItemSectionHeading {
  id?: string // guid relative to inclusion in Line Items
  type: 'section-heading'
  value: string
}

/**
 * Quote Line Item Section Heading
 * @param {string | null} id
 * @param {object} section
 * @param section.id
 * @param section.type
 * @param section.value
 * @returns section heading object
 */
export const toQuoteLineItemSectionHeading = (id: string | null = null, section: any = {}): QuoteLineItemSectionHeading => {
  return {
    id: id || getGuid(),
    type: section?.type || 'section-heading',
    value: section?.value || null
  }
}

export interface QuoteLineItemNote {
  id?: string // guid relative to inclusion in Line Items
  type: 'note'
  value: string
}

export const toQuoteLineItemNote = (id: string | null = null, note: any = {}): QuoteLineItemNote => {
  return {
    id: id || getGuid(),
    type: note?.type || 'note',
    value: note?.value
  }
}

export interface QuoteLineItemConfiguredItem extends ItemConfigured {
  id?: string // guid relative to inclusion in Line Items
  line_item_note: string
  line_item_scope: string
  price_override?: boolean
  price_override_reason?: string | null
  price_override_value?: number | null
  qty: number
  type: 'item'
}

export const toQuoteLineItemConfiguredItem = (fb_id: string | null = null, item: any): QuoteLineItemConfiguredItem => {
  return {
    id: item.id || getGuid(),
    ...toItemConfigured({ fb_id, item, item_type: ItemType.Configured }),
    line_item_note: item.line_item_note || null,
    line_item_scope: item.line_item_scope || lineItemScope.include.code,
    price_override: item.price_override || false,
    price_override_reason: item.price_override_reason || null,
    price_override_value: +item.price_override_value || null,
    qty: item.qty || 0,
    type: item.type || 'item'
  }
}

export interface QuoteLineItemNonConfiguredItem extends ItemNonConfigured {
  id?: string // guid relative to inclusion in Line Items
  line_item_note: string
  line_item_scope: string // should this be here or create a new extending interface on ILineItemItemQuote?
  price_override?: boolean
  price_override_reason?: string | null
  price_override_value?: number | null
  qty: number
  reason: string
  reason_notes: string
  resource1?: any // { label: string, value: string }
  type: 'non-configured-item'
}

/**
 *
 * @param fb_id This will be null on creation
 * @param item : object from Non-Configured form on doc
 * @returns non-configured item object
 */
export const toQuoteLineItemNonConfiguredItem = (fb_id: string | null = null, item: any): QuoteLineItemNonConfiguredItem => {
  const obj: any = {
    id: item.id || getGuid(),
    line_item_note: item.line_item_note || null,
    line_item_scope: item.line_item_scope || lineItemScope.include.code,
    price_override: item.price_override || false,
    price_override_reason: item.price_override_reason || null,
    price_override_value: +item.price_override_value || null,
    qty: item.qty || 0,
    resource1: item.resource1 || { label: null, value: null },
    reason: item.reason || null,
    reason_notes: item.reason_notes || null,
    type: item.type || 'non-configured-item',
    ...toItem({ fb_id, item, item_type: ItemType.NonConfigured })
  }
  return obj
}

export const toQuoteLineItems = (items: Array<any> = []): Array<QuoteLineItemConfiguredItem | QuoteLineItemNonConfiguredItem | QuoteLineItemNote | QuoteLineItemSectionHeading> => {
  if (!items.length)
    return []
  const result: any = []
  const types: any = {
    'item': toQuoteLineItemConfiguredItem,
    'non-configured-item': toQuoteLineItemNonConfiguredItem,
    'note': toQuoteLineItemNote,
    'section-heading': toQuoteLineItemSectionHeading
  }

  items.forEach((i: any) => result.push(types[i.type](i.fb_id, i)))
  return result
}
