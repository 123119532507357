/**
 * This will change value to uppercase with all spaces removed
 * It aids in a fuzzy search for item descriptions that may have been formatted incorrectly at some point. e.g. two spaces instead of one
 * A search description using this technique could result in multiple matches and is not a true primary key. However, it can also catch like items that should be combined. ¯\_(ツ)_/¯
 *
 * @param val Value to transform
 * @returns Uppercase string without spaces
 */
export const buildSearchDescription = (val: any): string => {
  if (!val || typeof val === 'object')
    return ''
  const s = val.toString()
  return s.toUpperCase().replace(/\s+/g, '')
}
