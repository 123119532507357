import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import PrimeVue from 'primevue/config'
import { primevueSetup } from './primevueSetup'
import pinia from './stores'
import router from './router'

import AppWrapper from './AppWrapper.vue'

// Styles
// import 'primevue/resources/themes/lara-light-blue/theme.css'
// import 'primevue/resources/primevue.min.css'

import './assets/styles/color_brown.css'
import 'primeflex/primeflex.css'
import './assets/styles/main.css'
import './assets/styles/print.css'
import './assets/styles/icon_overrides.css'
import DascoSpacer from './components/DascoSpacer.vue'

const app = createApp(AppWrapper)

Sentry.init({
  app,
  dsn: 'https://3c58c7f27e874fd5a007bd34ae7aecc0@o214277.ingest.sentry.io/4505547370332160',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: import.meta.env.VITE_APPLICATION_ENV === 'development' ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(PrimeVue, { ripple: true })
app.use(pinia)
app.use(router)
app.component('DSpacer', DascoSpacer)
await primevueSetup(app)

app.mount('#app')
