import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'
import QuoteRoutes from './QuoteRoutes'

const routes = [
  {
    path: '/',
    name: 'app',
    component: App,
    children: [
      ...QuoteRoutes
    ],
    redirect: '/quotes'
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
