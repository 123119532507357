// This quote store is for read only
// Use ActiveQuoteStore when editing
import { defineStore } from 'pinia'
import { useSentry } from '../composables/sentry'
import { FlowState } from '../constants/flowState'
import { priceDefault } from '../constants/priceDefault'
import type { ObjectKeys } from '../interfaces/ObjectKeys'
import type { ResponseSimple } from '../interfaces/Response'
import type { Note } from '../services/NoteServiceInterfaces'
import { type Quote, toQuote } from '../services/QuoteServiceInterface'
import QuoteServiceSharedAccess from '../services/QuoteServiceSharedAccess'
import StandardFootersService from '../services/StandardFootersService'
import { resetForGet } from './storeUtils'

const s = useSentry()

interface QuoteStore extends ObjectKeys {
  flow: FlowState
  flowFooters: FlowState
  flowPricing: FlowState
  hasPricingIssues: boolean
  history: any[]
  notes: Array<Note>
  notes_private: Array<Note>
  pricing: any
  quote: Quote
  showPricingIssuesDialog: boolean
  standardFooters: any[]
}

export const useQuoteStore = defineStore('QuoteStore', {
  state: (): QuoteStore => ({
    flow: FlowState.Idle,
    flowFooters: FlowState.Idle,
    flowPricing: FlowState.Idle,
    hasPricingIssues: false,
    history: [],
    notes: [],
    notes_private: [],
    quote: { ...toQuote() },
    pricing: {
      items: {},
      subtotal_list: null,
      subtotal_sell: null
    },
    showPricingIssuesDialog: false,
    standardFooters: []
  }),
  getters: {
    customerClass: (state: any) => state?.quote?.sell_to_account?.customer_price_group || 99,
    items: (state: any) => state.quote.items || [],
    itemsProducts(): any {
      return this.items.filter((i: any) => !['section-heading', 'note'].includes(i.type))
    }
  },
  actions: {
    evaluatePricingForIssues() {
      if (!this.quote?.id || !Object.keys(this?.pricing?.items)?.length) {
        this.hasPricingIssues = false
        this.showPricingIssuesDialog = false
      }
      else if (this.pricing.subtotal_sell === priceDefault) {
        this.hasPricingIssues = true
        this.showPricingIssuesDialog = true
      }
      else {
        this.hasPricingIssues = false
        this.showPricingIssuesDialog = false
      }
    },
    async getQuote(payload: any): Promise<ResponseSimple> {
      const {
        accessToken
      } = payload
      let resData: any = {}
      try {
        if (!accessToken) {
          return {
            success: true
          }
        }
        this.flow = FlowState.Loading

        resetForGet(this, ['notes'])
        const res = await QuoteServiceSharedAccess.getQuoteSharedAccess({
          accessToken
        })

        if (!res.success) {
          resData = { ...res.data }
          throw res.msg || 'Error retrieving quote'
        }

        this.quote = { ...res?.data?.quote }
        this.pricing = { ...res?.data?.pricing }
        this.evaluatePricingForIssues()
        return {
          success: true,
          data: res.data
        }
      }
      catch (error: any) {
        s.handleError(error)
        return {
          success: false,
          data: resData
        }
      }
      finally {
        this.flow = FlowState.Idle
      }
    },
    async getStandardFooters(): Promise<ResponseSimple> {
      this.flowFooters = FlowState.Loading
      try {
        const res = await StandardFootersService
          .getStandardFooters()

        if (!res.success)
          throw new Error(res.msg)

        this.standardFooters = res.data

        return {
          success: true,
        }
      }
      catch (error: any) {
        s.handleError(error)
        console.error(error)
        return {
          success: false
        }
      }
      finally {
        this.flowFooters = FlowState.Idle
      }
    }
  }
})
