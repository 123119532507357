import { FlowState } from '../constants/flowState'

/**
 *
 * @param {any} state Passed as `this`
 * @param {string[]} arrayPropsToClear List of props for store specific arrays to reset
 * @returns Store state reset to initial state
 */
export const resetForGet = (state: any, arrayPropsToClear = [] as Array<string>) => {
  state.flow = FlowState.Loading
  if (Object.hasOwn(state, 'editing')) {
    state.editing = false
    state.changesMade = []
    state.hasChanges = false
  }
  if (arrayPropsToClear.length) {
    arrayPropsToClear.forEach((i: string) => {
      state[i] = []
    })
  }
  return state
}
